<template>
  <div>
    <el-form-item label="提示文字">
      <el-input size="small" v-model="value.placeholder" placeholder="请设置提示语" />
    </el-form-item>
    <!-- <el-form label-position="top"> -->
    <el-form-item label="选项设置">
      <!-- <div slot="label" class="option-item-label">
          <span>选项设置</span> -->
      <el-cascader :options="selectList" @change="handleChange" v-model="notset"
        placeholder="未设置" size="mini"  :show-all-levels="false">
      </el-cascader>

    </el-form-item>
    <el-form style="border: dashed 1px gray; border-radius: 10px;margin: 5px;" v-if="notset == '1'"
      label-position="top">
      <el-button style="margin: 5px;" icon="el-icon-plus" type="text" size="mini"
        @click="value.options.push('新选项')">新增选项</el-button>
      <!-- </div> -->
      <draggable style="margin: 5px;" :list="value.options" group="option" handler=".el-icon-rank"
        :options="dragOption">
        <div v-for="(op, index) in value.options" :key="index" class="option-item">
          <i sizi="mini" class="el-icon-rank"></i>
          <el-input size="mini" v-model="value.options[index]" placeholder="请设置选项值" clearable>
            <el-button icon="el-icon-delete" slot="append" type="danger" size="mini"
              @click="value.options.splice(index, 1)"></el-button>
          </el-input>
        </div>
      </draggable>
    </el-form>
    <!-- </el-form> -->
    <el-form-item label="选项展开">
      <el-switch v-model="value.expanding"></el-switch>
    </el-form-item>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import { getPayWay } from "@/api/design";

export default {
  name: "SelectInputConfig",
  components: { draggable },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      opts: [],
      notset: '',
      selectList: [
        {
          value: '1',
          label: '自定义'
        },
        {
          value: '2',
          label: '系统数据',
          children: [{
            value: '3',
            label: '档案',
            children: [
              {
              value: '4',
              label: '支付方式'
              },
          ]},
        ]
        }
      ],
      dragOption: {
        animation: 300,
        sort: true
      }
    }
  },
  mounted() {
    this.notset = this.value.opts[this.value.opts.length - 1]
  },
  methods: {
    handleChange(e) {
      this.value.options = []
      this.value.opts = e
      if (e[e.length - 1] !== '1') {
        getPayWay().then(res => {
          this.value.options = res;
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .options {
  .el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
  }

  .el-icon-rank {
    padding-right: 5px;
    cursor: move;
  }

  .option-item {
    .el-input {
      width: 250px;
      float: right;
    }
  }
}

.option-item-label {
  height: 30px;
  line-height: 30px;

  button {
    float: right;
  }
}

/*/deep/ .el-form-item {
  margin-bottom: 10px;

  .el-form-item__label {
    padding: 0;
  }
  .options{
    .el-icon-rank{
      cursor: move;
    }
  }
}*/
</style>
